import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {connect} from "react-redux";
import {getWorks, getCatalogs} from "../../../redux/actions";
import Lightbox from "react-image-lightbox";
import PortfolioBox from "../../Elements/PortfolioBox/PortfolioBox";
import {Helmet} from "react-helmet";

function Works(props) {
    const [current, setCurrent] = useState('')
    const handleCloseModal = (e) => {
        e && e.preventDefault();
        setCurrent('')
    }
    const handleClickImage = (e, image) => {
        e && e.preventDefault();
        setCurrent(image)
    }
    const [id, setId] = useState(undefined)
    const pageslider = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 800,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows : false,
        rows: 1,
        centerPadding: "10px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3 ,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 561,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                }
            },
        ]
    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }
    const { getWorks, works, getCatalogs, catalogs } = props
    const [nworks , setWorks] = useState([])

    useEffect(() => {
        let mounted = true
        if(mounted) {
            !works.length && getWorks()
            !catalogs.length && getCatalogs()
        }
        setWorks(works)
    }, [1])

    const filterData = (id) =>{
       if (id){
           setWorks(
               works.filter((c)=>{return c.catalog_id === parseInt(id)})
           )
       }
       else{
           setWorks(works)
       }
    }

    return (
        <div className="main">
            <Helmet>
                <title>{'Portfolio'}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="section new chooseus-content col-xl-4 col-lg-4 col-md-12 col-sm-10 col-12 p-0">
                            <div className="section-header d-flex ">
                                <p>PORTFOLİOMUZDAN <strong>SON İŞLƏRİMİZ</strong></p>
                            </div>
                        </div>
                        <div className="works-links col-xl-6 col-lg-8 col-md-12 col-sm-10 col-10 p-0 d-flex justify-content-lg-end">
                            <div>
                                <ul className="d-sm-flex d-block p-0">
                                    <li>
                                        <span onClick={ () => {filterData(undefined)}} className="line-links">Hamısı</span>
                                    </li>
                                    {
                                        catalogs.filter((c) => {
                                            return c.type === 2
                                        }).map((c, i) => (
                                            <li>
                                                <span key={i} onClick={ () => {filterData(c.id)}} className="line-links">{c.locale.name}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            {
                                (nworks.length > 4 || works.length > 4)  &&
                                        <div className="d-flex works-arrows justify-content-end">
                                            <div className="work-arrow mr-2">
                                                <svg onClick={()=>{previous()}} xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                            </div>
                                            <div onClick={()=>{next()}} className="work-arrow ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                    <div className="works-slider">
                        <div className="hexagon-works">
                            {
                                nworks.length > 0 ?
                                    <>
                                        { nworks.length <= 4 ?
                                            <div className="slick-slide d-md-flex d-block ml-n5">
                                                {
                                                    nworks.map((work, i) => (
                                                        <PortfolioBox
                                                            key={i}
                                                            handleClickImage={handleClickImage}
                                                            onClick={e => handleClickImage(e, work.file.file)}
                                                            img={work.file.file}
                                                            title={work.locale.title}
                                                            description={work.locale.description}
                                                            up={!!(i % 2) || (i === 0 && false )}
                                                        />
                                                    ))
                                                }
                                            </div> :
                                            <Slider
                                                ref={slider => (pageslider.current = slider)}
                                                {...settings}>
                                                {
                                                    nworks.map((work, i) => (
                                                        <PortfolioBox
                                                            key={i}
                                                            handleClickImage={handleClickImage}
                                                            onClick={e => handleClickImage(e, work.file.file)}
                                                            img={work.file.file}
                                                            title={work.locale.title}
                                                            description={work.locale.description}
                                                            up={!!(i % 2 ) || (i === 0 && false )}
                                                        />
                                                    ))
                                                }
                                            </Slider>
                                        }
                                    </> :
                                    <>
                                        { works.length <= 4 ?
                                            <div className="slick-slide d-md-flex d-block ml-n5">
                                                {
                                                    works?.map((work, i) => (
                                                        <PortfolioBox
                                                            key={i}
                                                            handleClickImage={handleClickImage}
                                                            onClick={e => handleClickImage(e, work.file.file)}
                                                            img={work.file.file}
                                                            title={work.locale.title}
                                                            description={work.locale.description}
                                                            up={!!(i % 2) || (i === 0 && false )}
                                                        />
                                                    ))
                                                }
                                            </div> :
                                            <Slider
                                                ref={slider => (pageslider.current = slider)}
                                                {...settings}>
                                                {
                                                    works?.map((work, i) => (
                                                        <PortfolioBox
                                                            key={i}
                                                            handleClickImage={handleClickImage}
                                                            onClick={e => handleClickImage(e, work.file.file)}
                                                            file={work.file.file}
                                                            img={work.file.file}
                                                            title={work?.locale?.title}
                                                            description={work?.locale?.description}
                                                            up={!!(i % 2) || (i === 0 && false )}
                                                        />
                                                    ))
                                                }
                                            </Slider>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    {
                        current &&
                        <Lightbox mainSrc={current} onCloseRequest={handleCloseModal} />
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ works, catalogs }) => {
    return { works, catalogs };
}

export default connect(mapStateToProps, { getWorks, getCatalogs })(Works);

