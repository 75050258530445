import React, {useState , useEffect} from 'react';
import {Link , useLocation} from "react-router-dom";
import history from "../../../const/history";
import {setMenu , setNumber}  from "../../../redux/actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";


const PageTransition = (props) => {
    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(false);
    const location = useLocation()
    function debounce(func, timeout = 400){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const setLocalstorage = (numb) => {
        localStorage.setItem('numb' , numb)
    }

    const getLocalstorage = (key) => {
        return  parseInt(localStorage.getItem(key))
    }

    const links = ['', 'about', 'services', 'choose-us', 'works', 'leadership', 'vacancy' , 'news' , 'contact',];
    let indexPage = links.indexOf(history.location.pathname.substring(1));
    document.onmousewheel = debounce((e)=>{
        console.log('ON MOUSE WHEEL')
        if(!props.hover){
            if (indexPage !== -1){
                if (e.deltaY  > 0 && e.deltaY <= 200){
                    if(props.menu.pageNumber === links.length-1){
                        props.setNumber(0)
                    }
                    else {
                        props.setNumber(props.menu.pageNumber + 1)
                    }
                    props.setTransitionProps({
                        // sagdan sola
                        from: { opacity: 0, transform: "translate(100%, 0)" },
                        enter: { opacity: 1, transform: "translate(0%, 0)" },
                        leave: { opacity: 0, transform: "translate(-50%, 0)" }
                    })
                }
                if (e.deltaY  < 0 && e.deltaY >= -200){
                    if(props.menu.pageNumber === 0){
                        props.setNumber(links.length-1)
                    }
                    else {
                        props.setNumber(props.menu.pageNumber-1)
                    }
                    props.setTransitionProps({
                        // sagdan sola
                        from: { opacity: 0, transform: "translate(-50%, 0)" },
                        enter: { opacity: 1, transform: "translate(0%, 0)" },
                        leave: { opacity: 0, transform: "translate(100%, 0)" }
                        // kalbim kirildi , pepe bana hic inanmadi :(
                        // oysa istemeden kirmisdim ittirgiti
                        // ama o sevmiyor artik beni
                        // lalalal
                    })
                }
            }
        }
    })
    useEffect( () => {
       if (indexPage !== -1){
           console.log("Salam: ", props.menu.pageNumber);
           console.log("Path: ", window.location.pathname);
           if(props.menu.pageNumber === undefined){
               const num = links.indexOf(window.location.pathname.substr(1));
               props.setNumber(num);
               props.setMenu(pagedatas[num]);
           }
           else {
               props.setMenu(pagedatas[props.menu.pageNumber]);
               history.push(`/${links[props.menu.pageNumber]}`);
           }
       }
    }, [t, props.menu.pageNumber])


    const gotoNext = () => {
        const num = props.menu.pageNumber === undefined ? 0 : props.menu.pageNumber;
        const val = num === links.length - 1 ? 0 : num + 1;
        props.setNumber(val);
        props.setTransitionProps({
            // sagdan sola
            from: { opacity: 0, transform: "translate(100%, 0)" },
            enter: { opacity: 1, transform: "translate(0%, 0)" },
            leave: { opacity: 0, transform: "translate(-50%, 0)" }
        })
    }
    const gotoPrev = () =>{
        const num = props.menu.pageNumber === undefined ? 0 : props.menu.pageNumber;
        const val = num === 0 ? links.length - 1 : num - 1;
        props.setNumber(val);
        props.setTransitionProps({
            // saldan saga
            from: { opacity: 0, transform: "translate(-50%, 0)" },
            enter: { opacity: 1, transform: "translate(0%, 0)" },
            leave: { opacity: 0, transform: "translate(100%, 0)" }
        })
    }
    const pagedatas = [
        {
            name: t('home.page_transition.name'),
            next: t('home.page_transition.next'),
        },
        {
            name: t('about.page_transition.name'),
            next: t('about.page_transition.next'),
        },
        {
            name: 'XİDMƏTLƏRİMİZ',
            next: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
        },
        {
            name: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
            next: 'PORTFOLİO',
        },
        {
            name: 'PORTFOLİO',
            next: 'KOMANDAMIZ',
        },
        {
            name: 'KOMANDAMIZ',
            next: 'VAKANSIYALAR',
        },
        {
            name: 'VAKANSIYALAR',
            next: 'XƏBƏRLƏR VƏ ELANLAR',
        },
        {
            name: 'XƏBƏRLƏR VƏ ELANLAR',
            next: 'BİZİMLƏ ƏLAQƏ',
        },
        {
            name: t('contact.page_transition.name'),
            next: t('contact.page_transition.next'),
        },
    ]
    const getNextPageNumber = (num) => {
        return num === links.length - 1 ? '01' : (num + 2).toString().padStart(2,'0');
    }

    return (
        <>
            {!props.toggleReducer  &&
            <div>
                {links.indexOf(location.pathname.substring(1)) !== -1 &&
                    <>
                        <div className="page-num d-md-flex d-none justify-content-center">
                            <p>{getNextPageNumber(props.menu.pageNumber)}</p>
                        </div>
                        <div className="page-transition">
                            <div  className="link d-flex justify-content-end">
                                <p className="line">{props.menu.name}</p>
                            </div>
                            <div className="d-flex transition-text align-items-center">
                                <div className="number">
                                    <span>{(props.menu.pageNumber + 1).toString().padStart(2,'0')}</span>
                                </div>
                                <div onClick={() => {gotoNext()}} className='text-left maintext'>
                                    <span>
                                        {props.menu.next}
                                    </span>
                                </div>
                            </div>
                            <div className="arrows">
                                <div onClick={() => {
                                    gotoPrev()
                                }}>
                                    <Link to={'#'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.989" height="32.99"
                                             viewBox="0 0 9.989 32.99">
                                            <path
                                                d="M1560.12,743V713.314l-2.753,2.676-1.356-1.318,4.8-4.66.678.659.388-.378L1566,715l-1,1-2.82-2.641V743Z"
                                                transform="translate(-1556.011 -710.011)" fill="#fff"/>
                                        </svg>
                                    </Link>
                                </div>
                                <div onClick={() => {
                                    gotoNext()
                                }}>
                                    <Link to={'#'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.989" height="32.99"
                                             viewBox="0 0 9.989 32.99">
                                            <path
                                                d="M1556.011,787.329l1.356-1.319,2.753,2.676V759h2.06v29.641L1565,786l1,1-4.127,4.707-.388-.377-.678.66Z"
                                                transform="translate(-1556.011 -759)" fill="#fafcfb"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            }
        </>
    );
};

const mapStateToProps = ({ toggleReducer, hover, menu }) => {
    return { toggleReducer, hover, menu }
}
export default connect(mapStateToProps, { setMenu, setNumber })(PageTransition);
