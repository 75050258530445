export default  {
    TOGGLE: 'TOGGLE',
    HOVER: 'HOVER',
    OUT: 'OUT',
    SET_NUMBER: 'SET_NUMBER',
    SET_LANG: 'SET_LANG',
    GET_LANG: 'GET_LANG',
    GET_ABOUT: 'GET_ABOUT',
    GET_ABOUT_ERROR: 'GET_ABOUT_ERROR',
    GET_PARTNERS: 'GET_PARTNERS',
    GET_PARTNERS_ERROR: 'GET_PARTNERS_ERROR',
    GET_CERTIFICATES: 'GET_CERTIFICATES',
    GET_CERTIFICATES_ERROR: 'GET_CERTIFICATES_ERROR',
    GET_TEAMS: 'GET_TEAMS',
    GET_TEAMS_ERROR: 'GET_TEAMS_ERROR',
    GET_NEWS_PAGE: 'GET_NEWS_PAGE',
    GET_NEWS_ONE: 'GET_NEWS_ONE',
    GET_NEWS_PAGE_ERROR: 'GET_NEWS_PAGE_ERROR',
    GET_SERVICES: 'GET_SERVICES',
    GET_SERVICES_ERROR: 'GET_SERVICES_ERROR',
    GET_WORKS: 'GET_WORKS',
    GET_WORKS_ERROR: 'GET_WORKS_ERROR',
    GET_CATALOGS: 'GET_CATALOGS',
    GET_CATALOGS_ERROR: 'GET_CATALOGS_ERROR',
    GET_CHOOSE_US: 'GET_CHOOSE_US',
    GET_CHOOSE_US_ERROR: 'GET_CHOOSE_US_ERROR',
    GET_REVIEWS: 'GET_REVIEWS',
    GET_REVIEWS_ERROR: 'GET_REVIEWS_ERROR',
    GET_VACANCY: 'GET_VACANCY',
    GET_VACANCY_ERROR: 'GET_VACANCY_ERROR',
    GET_VACANCY_ONE: 'GET_VACANCY_ONE',
    GET_VACANCY_ONE_ERROR: 'GET_VACANCY_ONE_ERROR',
    SET_MENU_ITEM: 'SET_MENU_ITEM',
    SET_PAGE_NUMBER: 'SET_PAGE_NUMBER'
}
