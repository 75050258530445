import React, {useEffect, useRef, useState} from "react";
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import {useTranslation} from "react-i18next";
import { getAbout, getPartners, getCertificates, setHover } from "../../../redux/actions";
import {Helmet} from "react-helmet";

function About(props) {
    const  handleCloseModal = (e) =>{
        e && e.preventDefault();
        setCurrent('')
    }
    const handleClickImage = (e , image) =>{
        e && e.preventDefault();
        setCurrent(image)
    }
    const [current , setCurrent] = useState('')
    const {t } = useTranslation();

    const pageslider = useRef();

    const customerslider = useRef();

    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows : false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1 ,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };

    const settings2 = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    rows: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    rows: 2,
                }
            },
            {
                breakpoint: 561,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    rows: 1,
                }
            },

        ]
    };

    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }
    const next1 = () => {
        customerslider.current.slickNext();
    }
    const previous1 = () => {
        customerslider.current.slickPrev();
    }

    const { getAbout, about, getPartners, partners, certificates, getCertificates, setHover } = props

    useEffect(() => {
        let  mounted = true
        if(mounted){
            if (Object.keys(about).length === 0 ){
                getAbout();
            }
            if ( partners.length === 0) {
                getPartners()
            }
            if ( certificates.length === 0 ) {
                getCertificates()
            }
        }
        return function cleanup() {
            mounted = false
        }

    } , [t])
    return (
        <div className="main">
            <Helmet>
                <title>{t('about.text_header')}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="section col-xl-5 col-md-6 col-12 p-0">
                        <div className="section-about-header m-0">
                            <p className="text-white">{t('about.header')}</p>
                        </div>
                        <div onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}} className="section-about-description">
                            {
                                about.map((about) => (
                                    <div>
                                        <p className="text-white">{about.locale.title}</p>
                                        <span>{about.locale.description}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="d-flex mt-lg-4 mt-md-3 mt-sm-3 mt-3 align-items-center justify-content-between">
                            <Link to="/structure" className="d-flex section-link align-items-baseline">
                                <div>
                                    <img src={Image.Connection} alt=""/>
                                </div>
                                <div className="ml-lg-4 ml-md-3 ml-sm-2 ml-2">
                                    <p>{t('about.company_structure')}</p>
                                </div>
                            </Link>
                            {
                                partners.length > 6 &&
                                <div className="d-flex about-arrows justify-content-end">
                                    <div onClick={()=>{previous1()}} className="about-arrow mr-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                    </div>
                                    <div onClick={()=>{next1()}} className="about-arrow ml-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="companies-slider">
                            <div className="companies position-relative">
                                <div className="quote">
                                    <img src={Image.Quote} alt=""/>
                                </div>
                                <div className="companies-text">
                                    <p>{t('about.partners')}</p>
                                </div>
                                <div>
                                    {
                                        partners.length <= 3 ?
                                            <div className="d-flex">
                                                {
                                                    partners.map((partner, i) => (
                                                        <div key={i}>
                                                            <img src={partner.file.file} alt=""/>
                                                        </div>
                                                    ))
                                                }
                                            </div> :
                                            <Slider
                                                ref={slider => (customerslider.current = slider)}
                                                {...settings2}>
                                                {
                                                    partners.map((partner, i) => (
                                                        <div key={i}>
                                                            <img src={partner.file.file}  alt=""/>
                                                        </div>
                                                    ))
                                                }
                                            </Slider>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-12 p-0 lisence-slider">
                        {
                            certificates.length > 1 &&
                            <div className="lisence-arrow d-sm-flex d-none">
                                <div onClick={()=>{previous()}} >
                                    <div>
                                        <img src={Image.Left} alt=""/>
                                    </div>
                                </div>
                                <div onClick={()=>{next()}} >
                                    <div>
                                        <img src={Image.Right} alt=""/>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="lisence">
                            {
                                certificates.length < 2 ?
                                    <div>
                                        {
                                            certificates.map((certificate, i) => (
                                                <div key={i}>
                                                    <img src={certificate.file.file} onClick={e => handleClickImage(e, certificate.file.file)} alt=""/>
                                                </div>
                                            ) )
                                        }
                                    </div> :
                                    <Slider
                                        ref={slider => (pageslider.current = slider)}
                                        {...settings}>
                                        {
                                            certificates.map((certificate, i) => (
                                                <div key={i}>
                                                    <img src={certificate.file.file}  onClick={e => handleClickImage(e, certificate.file.file)} alt=""/>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    {current &&
                    <Lightbox
                        mainSrc={current}
                        onCloseRequest={handleCloseModal}
                    />
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ about, partners, certificates }) => {
    return  {about, partners, certificates } ;
}

export default connect(mapStateToProps, { getAbout, getPartners, getCertificates, setHover })(About);
