import React from 'react';

const PortfolioBox = ({ img , title , description , up , handleClickImage }) => {
    return (
        <div    onClick={e => handleClickImage(e, img)} className="hexagon-gallery-works animated zoomIn ">
            {up &&
                <div className="photo-text-up up mt-5 pt-5">
                    <p className="m-0">{title}</p>
                    <span>{description}</span>
                    {/*{new Date(work.created_at).getFullYear()}*/}
                </div>
            }
            <div className={`hex-works ${!up ? 'up' : 'down'}`}>
                <div className="hex-1">
                    <img src={img}  alt="some"></img>
                </div>
            </div>
            {!up &&
                <div className="photo-text-down down mb-md-5 mb-0 pb-md-5 pb-0 ">
                    <p className="m-0">{title}</p>
                    <span>{description}</span>
                    {/*{new Date(work.created_at).getFullYear()}*/}
                </div>
            }
        </div>
    );
}


export default PortfolioBox;
