import React, {useRef, useState} from 'react';
import api from "../../../const/api";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from 'react-input-mask';


function ApplyForm(props) {

    const reCaptha = useRef();
    const [fileName , setFileName] = useState(undefined)
    const {id} = props
    const [name , setName] = useState('');
    const [email , setEmail] = useState('')
    const [phone , setPhone] = useState('')
    const [text ,setText] = useState('')
    const [file ,setFile] = useState('')
    const [captchaKey , setCaptchaKey] = useState('');
    const [showSuccess , setshow] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [mailError,SetMailError] = useState(false);
    const [phoneError,setPhoneError] = useState(false);
    const [textError , setTextError] = useState(false);

    const showmessage = (set) =>{
        set(true)
        setTimeout(function(){set(false) }, 2000);
    }

    const  inputs1 = {n: name , error: nameError , setError : setNameError , setValue : setName , placeholder: 'Ad soyad ata adı' ,type:'text'} ;

    const  inputs = [
        // {n: name , error: nameError , setError : setNameError , setValue : setName , placeholder: 'Ad soyad ata adı' ,type:'text'},
        {n: email , error: mailError ,  setError : SetMailError , setValue : setEmail , placeholder: 'Email' ,type:'email'},
        {n: phone , error: phoneError, setError : setPhoneError, setValue: setPhone, placeholder: 'Telefon nömrəsi' , type: 'phone'},
        {n: text ,error: textError ,  setError : setTextError , setValue : setText ,  placeholder: 'Mesajınız' , type:'text'},
    ]

    function onChange(value) {
        setCaptchaKey(value);
    }


    const inputValidation = () =>{
        let sendM = false
        inputs.map((i) =>{
            if (i.n.trim() === null || i.n.trim() === "" || i.n ===" ") {
                showmessage(i.setError)
                sendM = false
            }
            else {
                sendM = true
            }
        })
        if (inputs1.n.trim() === null || inputs1.n.trim() === "" || inputs1.n ===" ") {
            showmessage(inputs1.setError)
            sendM = false
        }
        else {
            sendM = true
        }
        sendMessage(sendM)
    }

    const onsubmit = (e)=>{
        e.preventDefault()
        inputValidation()
    }

    const sendMessage = (sendM) =>{
        if (sendM){
            api
                .post("vacancy/apply", {
                    vacancy_id:id,
                    name,
                    email,
                    phone,
                    body: text,
                    captcha_key: captchaKey,
                    cv_uuid : file
                })
                .then((res) => {
                    showmessage(setshow)
                    setName('');
                    setText('');
                    setFile('')
                    setPhone('');
                    setEmail('');
                    setFileName(undefined)
                    setCaptchaKey('')
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }


    const onUpload = (e) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + e.target.files[0].name;
        form_data.append("file", e.target.files[0], filename);
        api
            .post("file/uploads/single/file", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFileName(e.target.files[0].name)
                setFile(res.data.data?.file_uuid)
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
            <>
                <form onSubmit={onsubmit} >
                    <h4>Apply For This Job</h4>
                    {showSuccess && <p className="text-success animated fadeIn">Mesajınız müvəffəqiyətlə göndərildi</p> }

                    <div className="row d-flex align-items-baseline">
                        <div className="vacancy-apply-form col-md-9">
                            <input value={inputs1.n} className={`${inputs1.error ? 'error' : 'mb-1' }`} type={inputs1.type} onChange={(e)=>{inputs1.setValue(e.target.value)}} placeholder={inputs1.placeholder}/>
                            {inputs1.error &&  <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span> }
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="upload-photo">{fileName ? fileName.slice(0 , 12) + "..." : 'CV Yüklə'}</label>
                            <input onChange={(e) =>{onUpload(e)}}  type="file" name="cv" id="upload-photo" />
                        </div>
                    </div>
                    {inputs.map((i)=>(
                        <>
                            {i.type === 'phone' ?
                                <>
                                    <div className="vacancy-apply-form">
                                         <InputMask placeholder={i.placeholder} {...props} mask="+\9\9\4999999999" maskChar=" " value={i.n} className={`${i.error ? 'error' : 'mb-1' }`} type={i.type} onChange={(e)=>{i.setValue(e.target.value)}}  />
                                    </div>
                                </> :
                                <div className="vacancy-apply-form">
                                    <input value={i.n} className={`${i.error ? 'error' : 'mb-1' }`} type={i.type} onChange={(e)=>{i.setValue(e.target.value)}} placeholder={i.placeholder}/>
                                    {i.error &&  <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span> }
                                </div>
                            }
                        </>
                    ))}
                    {/*<ReCAPTCHA*/}
                    {/*    className={"w-100 mt-2"}*/}
                    {/*    sitekey="6LciM_QaAAAAANKdeYRRK2-qbxcUZpDIa5bYx0qc"*/}
                    {/*    onChange={onChange}*/}
                    {/*/>*/}
                    <div className={'mt-2 d-flex align-items-center'}>
                        <button  type='submit' className="send-button font-weight-bold">
                            MESAJ GÖNDƏRİN
                        </button>
                    </div>

                </form>
            </>
    );
}

export default ApplyForm;
