export const en = {
    home: {
        slogan: 'The point where you stand is the center of the world!',
        mission: 'Mission : ',
        mission_text: ' We are building doors that open to the digital world. Our partnership with the government continues with innovative ideas and technologies to keep your personal information safe.',
        vision: 'Vision : ',
        vision_text: ' To constantly increase our productivity and quality in all directions with the strength of our labor and production in the field of ICT.',
        strategy: 'Strategy : ',
        strategy_text: ' It is our constant strategy to support the nationalization of investment in the field of ICT, to contribute to the creation and improvement of nationalInternet resources.',
        story: 'VİEW OUR STORY',

        page_transition: {
            name: 'HOME PAGE',
            next: 'WHO ARE WE? ABOUTOURCOMPANY',
        }
    },
    about: {
        header: 'WHO ARE WE?',
        text_header: 'ABOUT OUR COMPANY',
        text: 'The Virtual Azerbaijan group of companies, which started its activities in 2014, is currently one of the companies operating in the field of information technology in Azerbaijan. The activities of our company serve to automate the work carried out in the field of \n' +
            'business and government at various enterprises in Azerbaijan, as well as to increase productivity, efficiency, and optimization. Effective use of information technology to achieve this goal saves time and improvesworkflow efficiency. In a short time, we have signed a number of successful projects in this area and implemented a number of state projects in our country.',

        page_transition: {
            name: 'WHO ARE WE? ABOUT OUR COMPANY',
            next: 'OUR PROFESSİONAL SERVİCES',
        },
        partners: 'OUR PARTNERS, WHICH ARE ALWAYS WITH US',

        company_structure: 'COMPANY STRUCTURE',
    },
    contact: {
        header: 'CONTACT US',
        text_header: 'The activities of our company serve to automate the work carriedout in the field of business and government at various enterprises in Azerbaijan, as well as to increase productivity, efficiency, and optimization.',
        our_address: 'Our address :',
        address: 'Azerbaijan Baku. Time Business Center. 6th Floor',
        mobile: 'Contact by phone :',
        email: 'Contact by email :',

        page_transition: {
            name: 'CONTACT US',
            next: 'HOME PAGE',
        },
        contact_input: {
            contact_us: 'Get in touch',
            name: 'Name surname father\'s name',
            email: 'Email',
            message: 'Your message',
            send: 'Send'
        },
        validation: {
            success_message: 'Your message has been sent successfully',
            error_message: 'The cell must be filled',
        }
    }
}
