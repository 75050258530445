import types from "../types";
import vac from "../../const/api"


export const toggleMenu = () => {
    return {
        type: types.TOGGLE
    }
}

export const setHover = (hover) => {
    return {
        type: hover ? types.HOVER : types.OUT,
    };
};

export const changeLanguage = (payload) => {
    return {
        type: types.SET_LANG,
        payload,
    };
};


export const getAbout = () => async (dispatch) => {
    vac
        .get("about/us")
        .then((res) => {
            dispatch({
                type: types.GET_ABOUT,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_ABOUT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};
export const getPartners = () => async (dispatch) => {
    vac
        .get("partners" ,  {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_PARTNERS,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PARTNERS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getCertificates = () => async (dispatch) => {
    vac
        .get("certificates" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_CERTIFICATES,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CERTIFICATES_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getServices = () => async (dispatch) => {
    vac
        .get("services" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_SERVICES,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_SERVICES_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getWorks = () => async (dispatch) => {
    vac
        .get("portfolios" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_WORKS,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_WORKS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getTeams = () => async (dispatch) => {
    vac
        .get("our/teams" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_TEAMS,
                payload: res.data.data

            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_TEAMS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getChooseUs = () => async (dispatch) => {
    vac
        .get("choose-us" )
        .then((res) => {
            dispatch({
                type: types.GET_CHOOSE_US,
                payload: res.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CHOOSE_US_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getReviews = () => async (dispatch) => {
    vac
        .get("customer/reviews" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_REVIEWS,
                payload: res.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_REVIEWS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getNewsPage = (id) => async (dispatch) => {
    vac
        .get("news" , {params: {per_page :2000}})
        .then((res) => {
            const nid = parseInt(id);
            if(id){
                dispatch({
                    type: types.GET_NEWS_ONE,
                    payload: res.data.data.data.find((d) => { return d.id === nid })
                });
            }
            else {
                dispatch({
                    type: types.GET_NEWS_PAGE,
                    payload: res.data.data.data
                });
            }

        })
        .catch((err) => {
            dispatch({
                type: types.GET_NEWS_PAGE_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getCatalogs = () => async (dispatch) => {
    vac
        .get("catalogs" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_CATALOGS,
                payload: res.data.data.data

            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CATALOGS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};


export const getVacancy = () => async (dispatch) => {
    vac
        .get("vacancies" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_VACANCY,
                payload: res.data.data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_VACANCY_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getVacan = (id) => async (dispatch) => {
    vac
        .get("vacancies" , {params: {per_page :2000}})
        .then((res) => {
            dispatch({
                type: types.GET_VACANCY_ONE,
                payload: res.data.data.data.find(v => v.id === parseInt(id))
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_VACANCY_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const setNumber = (payload) => {
    return { type: types.SET_PAGE_NUMBER, payload };
}

export const setMenu = (payload) => {
    return { type: types.SET_MENU_ITEM, payload };
}
