import Logo from './Logo.svg'
import Main from './Main.png'
import MenuOpen from './menu-icon.svg'
import MenuClose from './close.png'
import VirtualAzerbaijan from './virtualaze.svg'
import Play from './play.svg'
import Facebook from './facebook.svg'
import Instagram from './instagram.svg'
import Twitter from './twitter.svg'
import Linkedin from './linkedin.svg'
import Vkontakte from './vk.svg'
import Wikipedia from './wikipedia.svg'
import Youtube from './youtube.svg'
import Mouse from './mouse.svg'
import Map from './map.svg'
import Brain from './Mask Group 1.png'
import Up from './up.svg'
import Down from './down.svg'
import Hex from './bg-hex.png'
import Connection from './connection.svg'
import Ultra from './ultra.svg'
import VisionTv from './visiontv.svg'
import Prosys from './prosys.svg'
import Datum from './datum.svg'
import Security from './securitycode.svg'
import Lisenziya from './VAC Lisenzya.svg'
import Quote from './left-quote.svg'
import Left from './left.svg'
import Right from './right.svg'
import Line from './line.svg'
import Plan from './plan.svg'
import Geo from './Geo.png'
import OperatingSystem from './operating-system.png'
import Last from './last.svg'
import Slash from './slash.svg'
import Next from './next.svg'
import ElvinAbbasov from './Elvin_Abbasov.jpg'
import Customer from './customer.jpg'
import Customer_Review from './customer_review.png'
import Customer_Review_2 from './customer_review_2.png'
import VisionTvLogo from './VisionTV-logo.svg'
import Layf from './Layf-logo.svg'
import Union from './logo-union.svg'
import Designer from './designer.svg'
import Case from './case.svg'
import FrontEnd from './front-end.svg'
import Dot from './dot.svg'
import PlayVideo from './play.png'
import BackArrow from './back-track.svg'
import Medical from './medical.jpg'
import BackLogo from './back-logo.png'
import Code from './Code.svg'





export default  {
    Logo,
    Code,
    Main,
    MenuOpen,
    VirtualAzerbaijan,
    Play,
    Facebook,
    Instagram,
    Twitter,
    Linkedin,
    Vkontakte,
    Wikipedia,
    MenuClose,
    Youtube,
    Mouse,
    Map,
    Brain,
    Up,
    Down,
    Hex,
    Connection,
    Ultra,
    VisionTv,
    Prosys,
    Datum,
    Security,
    Lisenziya,
    Quote,
    Left,
    Right,
    Line,
    Plan,
    Geo,
    OperatingSystem,
    Last,
    Slash,
    Next,
    ElvinAbbasov,
    Customer,
    VisionTvLogo,
    Layf,
    Union,
    Designer,
    Case,
    FrontEnd,
    Dot,
    PlayVideo,
    BackArrow,
    Medical,
    BackLogo,
    Customer_Review,
    Customer_Review_2,
}
