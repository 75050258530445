import React, {useContext, useEffect, useState} from "react";
import { Switch, Route, __RouterContext} from "react-router";
import { useTransition, animated } from "react-spring";
import FullVacancy from "../../Pages/FullVacancy/FullVacancy";
import NewsContent from "../../Pages/NewsContent/NewsContent";
import Home from "../../Pages/Home/Home";
import About from "../../Pages/About/About";
import Structure from "../../Pages/Structure/Structure";
import Services from "../../Pages/Services/Services";
import ChooseUs from "../../Pages/ChooseUs/ChooseUs";
import Works from "../../Pages/Works/Works";
import Leadership from "../../Pages/Leadership/Leadership";
import Vacancy from "../../Pages/Vacancy/Vacancy";
import News from "../../Pages/News/News";
import Contact from "../../Pages/Contact/Contact";
import PageTransition from "../../Elements/PageTransition/PageTransition";
import MenuList from "../../Elements/MenuList/MenuList";
import Image from "../../../assets/img/Images";

const Routes = (props) => {


    const [transitionProps , setTransitionProps] = useState(
        {
            // soldan saga
            from: { opacity: 0, transform: "translate(100%, 0)" },
            enter: { opacity: 1, transform: "translate(0%, 0)" },
            leave: { opacity: 0, transform: "translate(-50%, 0)" }
            // sagdan sola
        }
    )

    const {location} = useContext(__RouterContext);
    const transitions = useTransition(location , location => location.pathname,transitionProps);



    return (
        <div className={'animated fadeIn'}>
            <div className="cls-spinner zindex">
                {/*<div className="cls-bowtie cls-spin"></div>*/}
                <div className="loader">Loading...</div>
            </div>

            <>
                <PageTransition   setTransitionProps={setTransitionProps}/>
                {transitions.map(({item , props , key}) =>{
                    return (
                        <animated.div key={key} style={props}>
                            <Switch location={item}>
                                <Route path="/" component={Home} exact />
                                <Route path="/about" exact component={About} />
                                <Route path="/structure" exact component={Structure} />
                                <Route path="/about" exact component={About} />
                                <Route path="/services" exact component={Services} />
                                <Route path="/choose-us" exact  component={ChooseUs}/>
                                <Route path="/works" exact component={Works} />
                                <Route path="/leadership" exact component={Leadership} />
                                <Route path="/vacancy" exact component={Vacancy} />
                                <Route path="/news" exact component={News} />
                                <Route path="/contact" exact component={Contact} />
                                <Route path="/full-vacancy/:id?" exact component={FullVacancy} />
                                <Route path="/news-content/:id?" exact component={NewsContent} />
                            </Switch>
                        </animated.div>
                    )
                })}
                <MenuList/>
            </>
        </div>
    );
}

export default Routes;
