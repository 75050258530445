import React, {useRef, useState , useEffect} from 'react';
import api from "../../../const/api";
import Recaptcha from 'react-google-invisible-recaptcha';
import {useTranslation} from "react-i18next";

function ContactForm(props) {
    const {t} = useTranslation();
    const reCaptha = useRef();
    const trigger = 0
    const [name , setName] = useState('');
    const [email , setEmail] = useState('')
    const [text ,setText] = useState('')
    const [showSuccess , setshow] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [mailError,SetMailError] = useState(false);
    const [textError , setTextError] = useState(false);
    const [send, setSend]= useState(false)
    const showmessage = (set) =>{
        set(true)
        setTimeout(function(){set(false) }, 2000);
    }

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         reCaptha.current.execute()
    //     } , 3333)
    // } , [trigger])


    const  inputs = [
        {n: name , error: nameError , setError : setNameError , setValue : setName , placeholder: t('contact.contact_input.name') ,type:'text'},
        {n: email , error: mailError ,  setError : SetMailError , setValue : setEmail , placeholder: t('contact.contact_input.email') ,type:'email'},
        {n: text ,error: textError ,  setError : setTextError , setValue : setText ,  placeholder: t('contact.contact_input.message') , type:'text'},
    ]


    const inputValidation = () =>{
        let sendM = false
        inputs.map((i) =>{
            if (i.n.trim() === null || i.n.trim() === "" || i.n ===" ") {
                showmessage(i.setError)
                sendM = false
                reCaptha.current.reset()
            }
            else {
                sendM = true
            }
        })
        sendMessage(sendM)
    }

    const onsubmit = (e)=>{
        e.preventDefault()
        inputValidation()
    }

    const sendMessage = (sendM) =>{
      if (sendM){
          api
              .post("contact/us", {
                  name,
                  email,
                  body: text,
                  captcha_key: "03AGdBq24fFefgzYsGP0zWW9tNcLKdfjaeNZeLHFcdq9ihwtnNctdsjCx1qV_lwF-Fcnca9S1kp0vU1P167oXJ3DuDzCgyczC5F2EbYo_rrOBipRCOg4zwDYMm4ERKuLuEo4gGP-3zO-o2L3EIOJ0NJ96wKECLO_qtWVlw-MnRV7HUJ1IBJMR7-0HTK2bSAqAsugfB7X4ebNXLhjIGLcaecPmqjRzewWo6pzazQgfzpa_aFE5tLSflO76snEMZ6bm7szvd5Jo1AhvfuaKcq8TicpoI29QYE-auRNphrQ8XdT6tPMqmQRLKxgjcVwpRUVfJFtYOCdq9aF5369BPcZuZTHeuzVqdaZrpqe199E2gbzaAw2mj8UNPWlnutGlgQhRQlGtbf3amWW1a3qt06d-3_00lh8WXp4WWD_ytfWuq43ExE0ZTOE0H2Vy9nK2FbvOUfs0t0p1lrGB7WVls0X5v_LeNxYxsKalwtA"
              })
              .then((res) => {
                  showmessage(setshow)
                  setName('');
                  setText('');
                  setEmail('');
              })
              .catch((err) => {
                  console.log(err)
              });
      }
    }


    const  onResolved = ()  =>{
        alert(this.recaptcha.getResponse() );
    }

    return (
        <>
            <form onSubmit={onsubmit}>
                <h4 className="mb-4">{t('contact.contact_input.contact_us')}</h4>
                {showSuccess && <p className="text-success animated fadeIn">{t('contact.validation.success_message')}</p> }

                {inputs.map((i)=>(
                   <>
                       <input value={i.n} className={`${i.error ? 'error' : 'mb-4'}`} type={i.type} onChange={(e)=>{i.setValue(e.target.value)}} placeholder={i.placeholder}/>
                       {i.error &&  <span className=" animated fadeIn errortext text-danger">{t('contact.validation.error_message')}</span> }
                   </>
                ))}

                <div className={`mt-2`}>
                    <button  type='submit' className="button font-weight-bold">
                        {t('contact.contact_input.send')}
                    </button>
                </div>

                <Recaptcha

                    ref={ref  => (reCaptha.current = ref )}
                    sitekey="6LciM_QaAAAAANKdeYRRK2-qbxcUZpDIa5bYx0qc"
                    onResolved={onResolved} />

            </form>
        </>
    );
}

export default ContactForm;
