import React from 'react';
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <div>
            <div className="align-items-center justify-content-between footer">
                <div>
                    <span>© 2021 Virtual Azerbaijan. - All Rights Reserved.</span>
                </div>
                <div className="mouse mr-5 pr-5">
                    <Link><img src={Image.Mouse} alt=""/></Link>
                </div>
                <div className="social-icon">
                    <ul className="d-flex p-0 align-items-center justify-content-sm-around justify-content-between mt-sm-0 mt-4">
                        <a href={"https://www.facebook.com/virtualazerbaijan/"} target={"_blank"}><li><img src={Image.Facebook} alt=""/></li></a>
                        <Link to="/"><li><img src={Image.Instagram} alt=""/></li></Link>
                        <Link to="/"><li><img src={Image.Twitter} alt=""/></li></Link>
                        <Link to="/"><li><img src={Image.Linkedin} alt=""/></li></Link>
                        <a href={"https://az.wikipedia.org/wiki/Virtual_Azerbaijan_Group_of_Companies"} target={"_blank"}><li className="last-icon"><img src={Image.Wikipedia} alt=""/></li></a>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
