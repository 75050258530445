import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import {connect} from "react-redux";
import {getNewsPage} from "../../../redux/actions";
import moment from "moment";
import Lightbox from "react-image-lightbox";

function NewsContent(props) {
    const [current, setCurrent] = useState('')
    const handleCloseModal = (e) => {
        e && e.preventDefault();
        setCurrent('')
    }
    const handleClickImage = (e, image) => {
        e && e.preventDefault();
        setCurrent(image)
    }


    const {getNewsPage} = props
    const {id} = props.match.params
    useEffect( () => {
            getNewsPage(id)
    }, [id])

    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    <div>
                        <div className="p-0 align-items-center d-flex justify-content-between">
                            <div className="full-vacancy-content d-md-flex align-items-center">
                                <Link to="/news">
                                    <div className="d-flex align-items-center">
                                        <img src={Image.BackArrow} alt=""/>
                                        <p>BACK</p>
                                    </div>
                                    {/*<div className="photo-text">*/}
                                    {/*    <p className={'line-clamp line-2'}>{s.locale?.short_description}</p>*/}
                                    {/*    <span>Tarixi: </span>*/}
                                    {/*</div>*/}
                                </Link>
                                <div className="full-vacancy-header">
                                    <span>{moment(props.news.created_at).format("DD-MM-YYYY")}</span>
                                    <p>{props.news.locale?.title}</p>
                                </div>
                            </div>
                            <div className="vacancy-date mt-lg-0 mt-lg-0 mt-md-0 mt-sm-5 mt-4">
                                {/*<div>*/}
                                {/*    <p>Date Posted:</p>*/}
                                {/*    <span>17.03.2021</span>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className=" col-lg-5 col-md-12 col-12 contentImage">
                                <img src={props.news?.background?.file} onClick={e => handleClickImage(e, props.news?.background?.file)} alt=""/>
                            </div>
                            <div className="col-lg-7 col-md-12 col-12 news-content-text">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: props.news?.locale?.body
                                        }}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {current &&
                <Lightbox mainSrc={current} onCloseRequest={handleCloseModal} />
                }
            </div>
        </div>
    );
}

const mapStateToProps = ({ news }) => {
    return { news };
}

export default connect(mapStateToProps, { getNewsPage })(NewsContent);
