import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import Slider from "react-slick";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { getServices } from "../../../redux/actions";
import {Helmet} from "react-helmet";

function Services(props) {
    const {t } = useTranslation();
    const pageslider = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 2000,
                }
            },
        ]
    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }

    const {getServices, services} = props

    useEffect(() => {
        let  mounted = true
        if(mounted){
            if ( services.length === 0) {
                getServices()
            }
        }
        return function cleanup() {
            mounted = false
        }
    } , [t])

    const getFile = async (url) =>{
    }

    return (
        <div className="main">
            <Helmet>
                <title>{'Xidmətlərimiz'}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="section col-lg-8 col-md-12 col-sm-12 col-12 p-0">
                        <div className="section-text m-0">
                            <p>{t('services.header')}</p>
                        </div>
                        <div className="section-header d-flex">
                            <p className="font-weight-bold">{t('services.title')}</p>
                        </div>
                        <div>
                            <span>{t('services.description')}</span>
                        </div>
                    </div>
                    { services.length > 3 &&
                        <div className="d-flex service-arrows">
                            <div className="service-arrow">
                                <svg onClick={()=>{previous()}} xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                            </div>
                            <div className="service-arrow">
                                <svg onClick={()=>{next()}} xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                            </div>
                        </div>
                    }
                </div>

                <div className="service-slider">
                    <div className="services mt-lg-5 mt-lg-4 mt-sm-4 mt-4">
                        {
                            services.length &&
                                <>
                                    { services.length <= 3 ?
                                        <div className="col-md-11 col-12">
                                            <div className="row">
                                                {
                                                    services.map((service) => (
                                                        <div className="block col-lg-4 col-md-6 col-12 position-relative">
                                                            <div>
                                                                <p>{service.locale.title}</p>
                                                                <span>{service.locale.description}</span>
                                                            </div>
                                                            <div className="d-flex service-content">
                                                                <div className="services-link">
                                                                    {/*<Link>*/}
                                                                    {/*    318<br/>*/}
                                                                    {/*    PROJECTS*/}
                                                                    {/*</Link>*/}
                                                                    <Link>{service.projects_count}<br/>
                                                                        PROYEKT</Link>
                                                                </div>
                                                                <div className="service-line">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="122.712" height="122.712" viewBox="0 0 122.712 122.712"><path d="M681.7,2303.646l.659.659-67.819,67.819-54.235,54.234-.659-.658,55.584-55.585Z" transform="translate(-559.646 -2303.646)" fill="#28b36c"/></svg>
                                                                </div>
                                                                <div className="service-img">
                                                                    <img src={service.file.file} alt="" className="link"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> :
                                        <Slider
                                            ref={slider => (pageslider.current = slider)}
                                            {...settings}>
                                            {
                                                services.map((service) => (
                                                    <div className="d-flex">
                                                        <div className="block">
                                                            <div>
                                                                <p>{service.locale.title}</p>
                                                                <span>{service.locale.description}</span>
                                                            </div>
                                                            <div className="d-flex service-content">
                                                                <div className="services-link">
                                                                    <Link>{service.projects_count}<br/>
                                                                        PROYEKT</Link>
                                                                </div>
                                                                <div className="service-line">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="122.712" height="122.712" viewBox="0 0 122.712 122.712"><path d="M681.7,2303.646l.659.659-67.819,67.819-54.235,54.234-.659-.658,55.584-55.585Z" transform="translate(-559.646 -2303.646)" fill="#28b36c"/></svg>
                                                                </div>
                                                                <div className="service-img">
                                                                    <img src={service.file.file} alt="" className="link"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ services }) => {
    return { services };
}

export default connect(mapStateToProps, { getServices })(Services);
