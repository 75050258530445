import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {changeLanguage} from "../redux/actions";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Routes from "./Layout/Routes/Routes";
import Image from "../../src/assets/img/Images";

function App(props) {
    const [show , setShow] = useState(true)
    useEffect(() =>{
        if (!localStorage.getItem('locale')){
            localStorage.setItem("locale", "az");
        }
        else {
            props.changeLanguage(localStorage.getItem('locale'))
        }
    } )
    //
    useEffect(() => {
        setTimeout(()=>{
            setShow(false)
        },[2000])
    },[])

    return (
        <>
            {show ?
                <div >
                    <div className="loader">Loading...</div>
                </div> :
                <>
                    <Header />
                    <Routes />
                    <Footer />
                </>
            }

        </>
    );
}

const mapStateToProps = ({murad}) => {
    return {murad}
}

export default connect(({lang}) => lang , { changeLanguage })(App);
