import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {connect} from "react-redux";
import{setHover , getReviews , getChooseUs } from "../../../redux/actions";
import Lightbox from "react-image-lightbox";
import {Helmet} from "react-helmet";

function ChooseUs(props) {
    const {setHover , getReviews ,getChooseUs , choose, reviews} = props
    const  handleCloseModal = (e) =>{
        e && e.preventDefault();
        setCurrent('')

    }
    const handleClickImage = (e , image) =>{
        e && e.preventDefault();
        setCurrent(image)
    }
    const [current , setCurrent] = useState('')
    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    useEffect(()=>{
        let mounted = true
        if(mounted) {
            if (Object.keys(choose).length === 0) {
                getChooseUs();
            }
            if (reviews.length === 0) {
                getReviews();
            }
        }
        return function cleanup() {
            mounted = false
        }
    },  [props.render])
    const pageslider = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2 ,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 561,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    rows: 2,
                    vertical: true,
                    verticalSwiping: true,
                    beforeChange: function(currentSlide, nextSlide) {
                        console.log("before change", currentSlide, nextSlide);
                    },
                    afterChange: function(currentSlide) {
                        console.log("after change", currentSlide);
                    }
                }
            },
        ]
    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }
    return (
        <div className="main">
            <Helmet>
                <title>{'Bizi niyə seçməlisiniz ?'}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="section chooseus-content col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                        <div className="section-header d-lg-flex d-md-flex d-sm-block d-block ">
                            <p>BİZİ NİYƏ <strong>SEÇMƏLİSİNİZ ?</strong></p>
                        </div>
                        <div className="section-text-about" onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}>
                            <span>
                               {choose.locale?.description}
                            </span>
                        </div>
                        <div className="d-flex customers">
                            <div>
                                <p>Müştərilərdən təşəkkürlər</p>
                            </div>
                            {
                                reviews.length > 2 &&
                                <div className="d-flex customer-arrows justify-content-end">
                                    <div onClick={()=>{previous()}} className="customer-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                    </div>
                                    <div onClick={()=>{next()}}  className="customer-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="customer-slider">
                            <div className="hexagon">
                                <section className="hexagon-gallery d-flex">
                                    {
                                        reviews.length <= 2 ?
                                          <div className="d-sm-flex d-block">
                                              {
                                                  reviews.map((s, i) =>(
                                                      <div key={i} className="hex-one">
                                                          <Link>
                                                              <div className="hex mr-5">
                                                                  <img src={s.file.file} onClick={e => handleClickImage(e, s.file.file)} alt="some"/>
                                                              </div>
                                                          </Link>
                                                      </div>
                                                  ))
                                              }
                                          </div> :
                                            <Slider
                                                ref={slider => (pageslider.current = slider)}
                                                {...settings}>
                                                {
                                                    reviews.map((s, i) =>(
                                                        <div key={i} className="hex-one">
                                                            <Link>
                                                                <div className="hex">
                                                                    <img src={s.file.file} onClick={e => handleClickImage(e, s.file.file)} alt="some">
                                                                    </img>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                            </Slider>
                                    }

                                </section>
                            </div>
                        </div>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-lg-7 col-md-12 col-sm-12 col-12 p-0">
                        <div className="chooseus-text">
                            <div className="row w-100">
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>01</Link>
                                    <p>Proqram Təminatı</p>
                                    <span>İnformasiya emalının ən müasir və mükəmməl forması tətbiq edilir.</span>
                                </div>
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>02</Link>
                                    <p>Avadanlıq təminatı</p>
                                    <span>İşlərinizin rahatlığı üçün gərəkli olan bütün texniki vasitələrlə təmin edilir.</span>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>03</Link>
                                    <p>Peşəkar Planlama</p>
                                    <span>Layihənin hər addımı peşəkar mütəxəssislərimiz tərəfindən planlanır.</span>
                                </div>
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>04</Link>
                                    <p>Kreativ dizayn</p>
                                    <span>Peşəkar əməkdaşlarımız tərəfindən kreativ UI & UX işləri hazırlanır.</span>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>05</Link>
                                    <p>Mükəmməl Nəticə</p>
                                    <span>Komandamızın uğurlu işi nəticəsində tapşırıq ən mükəmməl şəkildə təhvil verilir</span>
                                </div>
                                <div  className={'col-xl-5 col-lg-6 col-12'}>
                                    <Link>06</Link>
                                    <p>7/24 dəstək</p>
                                    <span>Dəstək komandamız hər zaman işlərinizin yolunda getməsini təmin edir.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {current &&
                    <Lightbox
                        mainSrc={current}
                        onCloseRequest={handleCloseModal}
                    />
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ hover , choose, reviews}) => {
    return { hover , choose, reviews };
};

export default connect(mapStateToProps, { setHover , getReviews , getChooseUs })(ChooseUs);
