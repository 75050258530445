import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import Slider from "react-slick";
import {connect} from "react-redux";
import {getCatalogs , getNewsPage} from "../../../redux/actions";
import moment from "moment"
import {Helmet} from "react-helmet";
function News(props) {
    const pageslider = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        centerPadding: "-15px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    rows: 2,
                }
            },
        ]

    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }

    const {getCatalogs, catalogs} = props

    useEffect(() => {
        catalogs.length === 0 && getCatalogs()
        props.newsPage.length === 0 && props.getNewsPage()
    }, [props.render])
    return (
        <div className="main">
            <Helmet>
                <title>{'Xəbərlər və Elanlar'}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="section new chooseus-content news col-lg-3 col-md-6 col-sm-10 col-12 p-0">
                            <div className="section-header">
                                <p>XƏBƏRLƏR VƏ <strong>ELANLAR</strong></p>
                            </div>
                        </div>
                        <div className="works-links col-lg-7 col-md-8 col-sm-10 col-10 p-0 d-flex justify-content-lg-end">
                            <div>
                                <ul className="d-md-flex d-block p-0">
                                    {
                                        catalogs.filter((c) => {
                                            return c.type === 4
                                        }).map((c, i) => (
                                            <li>
                                                <Link className="line-links">{c.locale.name}</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            {
                                props.newsPage.length > 6 &&
                                <div className="d-flex news-arrows justify-content-end">
                                    <div onClick={()=>{previous()}} className="new-arrow mr-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                    </div>
                                    <div onClick={()=>{next()}} className="new-arrow ml-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="news-slider">
                        <div className="row col-md-11 col-12 news-img">
                            {props.newsPage.length < 6 ?
                                <>
                                    {
                                        props.newsPage.map((s, i) =>(
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-0">
                                                <Link to={`news-content/${s.id}`}>
                                                    <div className="img">
                                                        <Link className="play-button">
                                                            <img src={Image.PlayVideo} alt=""/>
                                                        </Link>
                                                        <div className="overlay-img"></div>
                                                        <img src={s.background.file} alt=""/>
                                                        <div className="photo-text">
                                                            <p className={'line-clamp line-1'}>{s.locale?.title}</p>
                                                            <p className={'line-clamp line-2'}>{s.locale?.short_description}</p>
                                                            <span>Tarixi: {moment(s.created_at).format("DD-MM-YYYY")}</span>
                                                            {/*<span>Tarixi:  {s.created_at}</span>*/}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </>
                                :
                               <>
                                   <Slider
                                       ref={slider => (pageslider.current = slider)}
                                       {...settings}>
                                       {
                                           props.newsPage.map((s, i) =>(
                                               <div className=" p-0">
                                                   <Link to={s?.url ? s.url : `/news-content/${s.id}`}>
                                                       <div className="img">
                                                           <Link className="play-button">
                                                               <img src={Image.PlayVideo} alt=""/>
                                                           </Link>
                                                           <div className="overlay-img"></div>
                                                           <img src={s.background?.file} alt=""/>
                                                           <div className="photo-text">
                                                               <p className={'line-clamp line-1'}>{s.locale?.title}</p>
                                                               <p className={'line-clamp line-2'}>{s.locale?.short_description}</p>
                                                               <span>Tarixi: {moment(s.created_at).format("DD-MM-YYYY")}</span>
                                                           </div>
                                                       </div>
                                                   </Link>
                                               </div>
                                           ))
                                       }
                                   </Slider>
                               </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({catalogs , newsPage}) => {
    return {catalogs , newsPage};
}


export default connect(mapStateToProps, {getCatalogs , getNewsPage}) (News);
