export const ru = {
    home: {
        slogan: 'Точка, в которой вы стоите, - это центр мира!',
        mission: 'Миссия : ',
        mission_text: ' Мы открываем двери в цифровой мир.Наше партнерство с правительством продолжается\n' +
            'с использованием инновационных идей и технологий для обеспечения безопасности вашей личной информации.',
        vision: 'Видение : ',
        vision_text: ' Постоянно повышать нашу производительность и качество во всех направлениях за счет силы нашего труда и производства в области ИКТ.',
        strategy: 'Стратегия : ',
        strategy_text: ' Hаша неизменная стратегия - поддерживать национализацию инвестиций в сферу ИКТ, способствовать созданию и совершенствованию национальных Интернет-ресурсов. ',
        story: 'ПОСМОТРЕТЬ НАШУ ИСТОРИЮ',

        page_transition: {
            name: 'ГЛАВНАЯ СТРАНИЦА',
            next: 'КТО МЫ? О НАШЕЙ КОМПАНИИ',
        }
    },
    about: {
        header: 'КТО МЫ?',
        text_header: 'О НАШЕЙ КОМПАНИИ',
        text: 'Группа компаний Virtual Azerbaijan, начавшая свою деятельность в 2014 году, в настоящее время является одной из компаний, работающих в сфере информационных технологий в Азербайджане. Деятельность нашей компании служит для автоматизации работы, выполняемой в сфере бизнеса и государственного управления на различных предприятиях Азербайджана, а также для повышения производительности, эффективности, а также оптимизации. Эффективное использование информационных технологий для достижения этой цели экономит время и повышает эффективность рабочего процесса. За короткое время мы подписали ряд успешных проектов в этой сфере и реализовали ряд государственных проектов в нашей стране.',

        page_transition: {
            name: 'КТО МЫ? О НАШЕЙ КОМПАНИИ',
            next: 'НАШИ ПРОФЕССИОНАЛЬНЫЕ УСЛУГИ',
        },
        partners: 'НАШИ ПАРТНЕРЫ, КОТОРЫЙ ВСЕГДА С НАМИ',
    },
    contact: {
        header: 'СВЯЗАТЬСЯ С НАМИ',
        text_header: 'Деятельность нашей компании служит для автоматизации работы, выполняемой в сфере бизнеса и государственного управления на различных предприятиях Азербайджана, а также дляповышения производительности, эффективности, а также оптимизации.',
        our_address: 'Наш адрес :',
        address: 'Азербайджан Баку. Бизнес-центр Time. 6 эт.',
        mobile: 'Cвязаться с телефоном :',
        email: 'Cвязаться по электронной почте :',

        page_transition: {
            name: 'СВЯЗАТЬСЯ С НАМИ',
            next: 'ГЛАВНАЯ СТРАНИЦА',
        },
        contact_input: {
            contact_us: 'Связаться',
            name: 'Имя фамилия имя отца',
            email: 'Е-мейл',
            message: 'Bаше сообщение',
            send: 'Послать'
        },
        validation: {
            success_message: 'Ваше сообщение было отправлено успешно',
            error_message: 'Ячейка должна быть заполнена',
        }
    }
}
