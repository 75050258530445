export const az = {
    home: {
        slogan: 'Dayandığınız nöqtə dünyanın mərkəzidir!',
        mission: 'Misiya :',
        mission_text: 'Biz sizə rəqəmsəl dünyaya acılan qapılar inşa edirik.\n' +
            '                                    Şəxsi məlumatlarınızın güvənli olması üçün hökumətlə olan iş birliyimiz innovativ ideya və texnologiyalarla davam edir.',
        vision: 'Vision :',
        vision_text: ' İKT sahəsində öz əmək və istehsalatımızın gücü ilə  bütün istiqamətlərdə məhsuldarlığımızı və keyfiyyətimizi daim artırmaq.',
        strategy: 'Strategiya : ',
        strategy_text: ' İKT sahəsində Sərmayənin milləşməsinə dəstək olmaq, Milli internet resurslarının yaranmasına və təkminləşməsinə töhvə vermək dəyişməz strategiyamızdır.',
        story: 'BİZİM TARİXÇƏMİZƏ BAXIN',

        page_transition: {
            name: 'ANA SƏHİFƏ',
            next: 'BİZ KİMİK? ŞİRKƏTİMİZ HAQQINDA',
        }
    },
    about: {
        header: 'BİZ KİMİK?',
        text_header: 'ŞİRKƏTİMİZ HAQQINDA',
        text: '2014-ci ildən fəaliyyətə başlayan "Virtual Azərbaijan Group of Company" şirkəti hazırda Azərbaycanda İT sahəsində fəaliyyət göstərənşirkətlərdən biridir. Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət idarəçiliyində görülən işlərin avtomatlaşdırılmasına və məhsuldarlığının, effektivliyinin artırılması, həmçinin optimallaşdırılmasına xidmət edir. Bu hədəfə çatmaq üçün İnformasiya Texnologiyalarından effektiv istifadə etməklə vaxta qənaətvə daha səmərəli iş prosesi əldə edilir. Biz qısa zaman ərzində bu sahədə bir sıra uğurlu layihələrə imza atmışıq və ölkəmizdə bir sıra dövlət layihələrini icra etmişik.',

        page_transition: {
            name: 'BİZ KİMİK?',
            next: 'PROFESSİONAL XİDMƏTLƏRİMİZ',
        },
        partners: 'DAİM BİZİMLƏ OLAN PARTNYORLARIMIZ',

        company_structure: 'ŞİRKƏTİN STRUKTURU',
    },
    services: {
        header: 'PROFESSİONAL',
        title: 'XİDMƏTLƏRİMİZ',
        description: 'Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət idarəçiliyində görülən işləri avtomatlaşdırmaq və məhsuldarlığının, effektivliyinin artırılması, həmçinin optimallaşdırılmasına xidmət edir.'
    },

    contact: {
        header: 'BİZİMLƏ ƏLAQƏ',
        text_header: 'Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət\n' +
            '                                    idarəçiliyində görülən işləri avtomatlaşdırmaq və məhsuldarlığının, effektivliyinin\n' +
            '                                    artırılması, həmçinin optimallaşdırılmasına xidmət edir.',
        our_address: 'Ünvanımız :',
        address: 'Azərbaycan Bakı. Time Biznes Mərkəzi. 6-cı mərtəbə',
        mobile: 'Mobil ilə əlaqə :',
        email: 'E-poçt ilə əlaqə :',

        page_transition: {
            name: 'BİZİMLƏ ƏLAQƏ',
            next: 'ANA SƏHİFƏ',
        },
        contact_input: {
            contact_us: 'Əlaqə saxla',
            name: 'Ad soyad ata adı',
            email: 'Email',
            message: 'Mesajınız',
            send: 'GÖNDƏR',
        },
        validation: {
            success_message: 'Mesajınız müvəffəqiyətlə göndərildi',
            error_message: 'Xana doldurulmalıdır',
        }
    }
}
